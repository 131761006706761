/* eslint-disable no-useless-escape */

import numeral from 'numeral';

// ----------------------------------------------------------------------

type InputValue = string | number | null;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fFloat(value: any, fixed = 2): number {
  let v = value.toString();
  v = v.slice(0, v.indexOf('.') + fixed + 1);
  return Number(v);
}

export function formatCurrency(value: string): string {
  let aux: any = value.replace(/\D/g, '');
  aux = `${(aux / 100).toFixed(2)}`;
  aux = aux.replace('.', ',');
  aux = aux.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return aux;
}

export function toFloat(price: string): number {
  if (!price) return 0;
  const value = price
    ?.replace('R$', '')
    .replace(/\./g, '')
    .replace(',', '.')
    .trim();
  return fFloat(value);
}

export function fCurrency(value: number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

export function fCurrencyMask(value: string) {
  const formattedValue = value
    .replace('.', '')
    .replace(',', '')
    .replace(/\D/g, '');
  const maskedValue = fCurrency(parseFloat(formattedValue) / 100);
  return maskedValue;
}

export function formatAsCurrency(value: number): string {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(value);
}

export function currencyStringToNumber(currency: string): number {
  if (currency.length < 1) return 0;

  const cleanedString = currency.replace(/[R$\s\.]/g, '').replace(',', '.');
  const numberValue = parseFloat(cleanedString);

  if (Number.isNaN(numberValue)) {
    throw new Error('Invalid currency format');
  }

  return numberValue;
}

export function realToNumber(realStr: string): number {
  const cleanedStr = realStr
    .replace('R$', '')
    .trim()
    .replace(/\./g, '')
    .replace(',', '.');

  const number = parseFloat(cleanedStr);

  return number;
}

export function fPercent(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export function fPhone(value: string) {
  return value
    ?.replace(/\D+/g, '')
    .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
