/* eslint-disable no-prototype-builtins */
import Iconify from '@/components/iconify';
import { useIsMount } from '@/hooks/useIsMount';
import { Gen } from '@/utils/gen';
import {
  AutocompleteProps,
  CircularProgress,
  IconButton,
  List,
  Autocomplete as MuiAutocomplete,
  Stack,
  TextField,
} from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import update from 'immutability-helper';

import {
  Children,
  cloneElement,
  createContext,
  forwardRef,
  HTMLAttributes,
  ReactChild,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  ControllerRenderProps,
  FieldError,
  FieldValues,
} from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

type Fn = () => any;

type NullableUlElement = HTMLUListElement | null;

const LISTBOX_PADDING = 8;

const Loader = (
  <Stack alignItems="center" justifyContent="center" sx={{ py: 2 }}>
    <CircularProgress size={20} />
  </Stack>
);

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;

  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  } as any;

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="div" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet}
    </Typography>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const setListboxComponent = (additionalHeight = 0) => {
  return forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
      const { children, ...other } = props;

      const itemData: ReactChild[] = [];
      (children as ReactChild[]).forEach(
        (item: ReactChild & { children?: ReactChild[] }) => {
          itemData.push(item);
          itemData.push(...(item.children || []));
        }
      );

      const itemCount = itemData.length;

      const itemSize = 36 + additionalHeight;

      const getChildSize = (child: ReactChild) => {
        if (child.hasOwnProperty('group')) {
          return 48 + additionalHeight;
        }

        return itemSize;
      };

      const getHeight = () => {
        if (itemCount > 8) {
          return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
      };

      const gridRef = useResetCache(itemCount);

      return (
        <div ref={ref}>
          <OuterElementContext.Provider value={other}>
            <VariableSizeList
              itemData={itemData}
              height={getHeight() + 2 * LISTBOX_PADDING}
              width="100%"
              ref={gridRef}
              outerElementType={OuterElementType}
              innerElementType="ul"
              itemSize={(index: any) => getChildSize(itemData[index])}
              overscanCount={5}
              itemCount={itemCount}
            >
              {renderRow}
            </VariableSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    }
  );
};

const setStyledPopper = (additionalHeight = 0) => {
  return styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0,

        li: {
          borderRadius: 0,
          height: 32 + additionalHeight,
        },
      },
    },
  });
};

const setListboxWithInfiniteScrollComponent = (
  dataLength: number,
  hasMore: boolean,
  isSearching: boolean,
  handleNext: Fn
) => {
  return forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
      const updatedChildren = Children.map(props.children, child => {
        const key = Gen.UUID();
        return cloneElement(child as any, { key });
      });

      const updatedProps = update(props, {
        children: {
          $set: updatedChildren,
        },
      });

      const { children, ...rest } = updatedProps;

      const innerRef = useRef<HTMLUListElement>(null);

      useImperativeHandle<NullableUlElement, NullableUlElement>(
        ref,
        () => innerRef.current
      );

      return isSearching ? (
        Loader
      ) : (
        <List
          {...rest}
          id="scrollableAutocomplete"
          ref={innerRef}
          role="list-box"
        >
          <InfiniteScroll
            next={handleNext}
            dataLength={dataLength ?? 20}
            hasMore={hasMore}
            loader={Loader}
            scrollThreshold={1}
            height={250}
          >
            {children}
          </InfiniteScroll>
        </List>
      );
    }
  );
};

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  label: string;
  additionalHeight?: number;
  isError?: boolean;
  error?: FieldError | undefined;
  field?: ControllerRenderProps<FieldValues, string>;
  isLoading?: boolean;
  isSearching?: boolean;
  dataLength?: number;
  hasMore?: boolean;
  searchDebounce?: number;
  onLoadMore?: () => void;
  onSearch?: (value: string) => void;
}

export function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  label,
  additionalHeight = 0,
  error,
  field,
  isLoading = false,
  isSearching = false,
  dataLength = 0,
  hasMore = false,
  onLoadMore,
  onSearch,
  ...other
}: Props<T, Multiple, DisableClearable, FreeSolo>) {
  const isMount = useIsMount();

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!isMount) {
      if (!search) {
        onSearch?.(search);
      }
    }
  }, [search]);

  return (
    <MuiAutocomplete
      disableListWrap
      PopperComponent={setStyledPopper(additionalHeight)}
      ListboxComponent={
        onLoadMore
          ? setListboxWithInfiniteScrollComponent(
              dataLength,
              hasMore,
              isSearching,
              onLoadMore
            )
          : setListboxComponent(additionalHeight)
      }
      noOptionsText={
        isLoading || isSearching ? (
          <Stack direction="row" spacing={1.5}>
            <span>Buscando...</span>
            <CircularProgress color="inherit" size={20} />
          </Stack>
        ) : (
          'Nenhum resultado encontrado'
        )
      }
      {...(onLoadMore &&
        onSearch && {
          onInputChange: (_, value) => {
            setSearch(value);
          },
          onKeyDown: e => {
            if (e.key === 'Enter' && !isSearching) {
              e.preventDefault();
              onSearch(search);
            }
          },
          inputValue: search,
        })}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={error?.message || ' '}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {onSearch && (
                  <IconButton
                    type="button"
                    aria-label="search"
                    onClick={() => onSearch?.(search)}
                    disabled={isSearching}
                  >
                    <Iconify icon="mdi:search" />
                  </IconButton>
                )}

                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...field}
      {...other}
    />
  );
}
