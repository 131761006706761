// form
import { TextField, TextFieldProps } from '@mui/material';

import { Controller, useFormContext } from 'react-hook-form';
// @mui

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
};

export default function RHFTextField({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
        <TextField
          {...fieldProps}
          inputRef={ref}
          fullWidth
          value={
            typeof fieldProps.value === 'number' && fieldProps.value === 0
              ? ''
              : fieldProps.value
          }
          error={!!error}
          helperText={error?.message || ' '}
          {...other}
        />
      )}
    />
  );
}
