export interface AspectRatioReturn {
  width: string;
  height: string;
}

export interface LoadedImageReturn {
  src: string;
  isLoading: boolean;
  size: [number, number];
}

export const INITIAL_IMAGE_RETURN: LoadedImageReturn = {
  src: process.env.NEXT_PUBLIC_PRODUCT_PLACEHOLDER_URL as string,
  isLoading: true,
  size: [0, 0],
};

export function toFilePreview(file: File): File {
  return Object.assign(file, {
    preview: URL.createObjectURL(file),
  });
}

export function calculateAspectRatio(
  size: [number, number]
): AspectRatioReturn {
  const [width, height] = size;

  let imageWidth;
  let imageHeight;

  if (width > height) {
    imageWidth = '100%';
    imageHeight = 'auto';
  } else {
    imageWidth = 'auto';
    imageHeight = '100%';
  }

  return {
    width: imageWidth,
    height: imageHeight,
  };
}

export async function loadImage(src: string): Promise<LoadedImageReturn> {
  return new Promise(resolve => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      resolve({
        ...INITIAL_IMAGE_RETURN,
        isLoading: false,
        src: img.src,
        size: [img.width, img.height],
      });
    };

    img.onerror = () => {
      resolve({
        ...INITIAL_IMAGE_RETURN,
        isLoading: false,
        size: [img.width, img.height],
      });
    };
  });
}
