import { MenuItem, TextField, TextFieldProps } from '@mui/material';

import { Controller, useFormContext } from 'react-hook-form';

type TextFieldOptionProps = {
  value: string | number;
  label: string;
  hidden?: boolean;
};

type Props = TextFieldProps & {
  name: string;
  options: TextFieldOptionProps[];
};

export default function RHFSelect({ name, options, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          error={!!error}
          helperText={error?.message || ' '}
          {...other}
        >
          {options.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              {...(option?.hidden && {
                sx: { display: 'none' },
              })}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
}
