// form
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControlLabelProps,
  Box,
  SxProps,
} from '@mui/material';

import { useFormContext, Controller } from 'react-hook-form';
// @mui

// ----------------------------------------------------------------------

interface RHFCheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
}

export function RHFCheckbox({ name, ...other }: RHFCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox {...field} checked={field.value} />}
          {...other}
        />
      )}
    />
  );
}

// ----------------------------------------------------------------------

interface RHFMultiCheckboxProps
  extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  options: {
    label: string;
    value: any;
    additionalComponent?: React.ReactNode;
  }[];
  formGroupSx?: SxProps;
  optionBoxSx?: SxProps;
}

export function RHFMultiCheckbox({
  name,
  options,
  formGroupSx,
  optionBoxSx,
  ...other
}: RHFMultiCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: string) =>
          field.value.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option];

        return (
          <FormGroup sx={formGroupSx}>
            {options.map(option => (
              <Box display="flex" sx={optionBoxSx} key={option.value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value.includes(option.value)}
                      onChange={() => field.onChange(onSelected(option.value))}
                    />
                  }
                  label={option.label}
                  {...other}
                />
                {option.additionalComponent}
              </Box>
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
