import { AutocompleteProps } from '@mui/material';

import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete } from '../autocomplete';

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  name: string;
  label: string;
  additionalHeight?: number;
  isError?: boolean;
  // Infinite Scroll
  isLoading?: boolean;
  isSearching?: boolean;
  dataLength?: number;
  hasMore?: boolean;
  searchDebounce?: number;
  onLoadMore?: () => void;
  onSearch?: (value: string) => void;
}

export default function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  label,
  additionalHeight = 0,
  loading = false,
  isError = false,
  // Infinite Scroll
  isLoading = false,
  isSearching = false,
  dataLength = 0,
  hasMore = false,
  searchDebounce = 300,
  onLoadMore,
  onSearch,
  ...other
}: Props<T, Multiple, DisableClearable, FreeSolo>) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          label={label}
          additionalHeight={additionalHeight}
          loading={loading}
          isError={isError}
          // React hook form
          error={error}
          field={field}
          // Infinite Scroll
          isLoading={isLoading}
          isSearching={isSearching}
          dataLength={dataLength}
          hasMore={hasMore}
          searchDebounce={searchDebounce}
          onLoadMore={onLoadMore}
          onSearch={onSearch}
          {...other}
        />
      )}
    />
  );
}
