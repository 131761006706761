import {
  Radio,
  RadioGroup,
  FormHelperText,
  RadioGroupProps,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { Box, SxProps } from '@mui/system';

import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

type Props = RadioGroupProps & {
  name: string;
  options: {
    label: string;
    value: any;
    description?: string;
    additionalComponent?: React.ReactNode;
  }[];
  labelTitleStyle?: any;
  optionBoxStyle?: SxProps;
};

export default function RHFRadioGroup({
  name,
  options,
  labelTitleStyle,
  optionBoxStyle,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup {...field} row {...other}>
            {options.map(option => (
              <Box
                sx={optionBoxStyle}
                display="flex"
                key={option.value || option.label}
              >
                <FormControlLabel
                  key={option.value || option.label}
                  value={option.value}
                  control={<Radio />}
                  label={
                    <Stack sx={{ marginLeft: '8px' }}>
                      <span style={labelTitleStyle}>{option.label}</span>

                      {option.description && (
                        <span
                          style={{
                            color: '#637381',
                            fontSize: '14px',
                          }}
                        >
                          {option.description}
                        </span>
                      )}
                    </Stack>
                  }
                />
                {option.additionalComponent}
              </Box>
            ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
