import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  List,
  ListItem,
  Popper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';

import Iconify from '../iconify';

export interface AutoCompleteValueInterface {
  label: string;
  id: string;
}

interface Props {
  name: string;
  options: AutoCompleteValueInterface[];
  isLoadingNewPage?: boolean;
  placeholder?: string;
  loadItems: (term: string, isChangingPage?: boolean) => void;
  label?: string;
  hasMorePages: boolean;
  value: string;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onSelectOption: (value: AutoCompleteValueInterface) => void;
  notFoundText?: string;
  setValue?: Dispatch<SetStateAction<string>>;
  initialSelectedValue?: AutoCompleteValueInterface;
  halfWidth?: boolean;
  disabled?: boolean;
}

const Loader = (
  <Stack alignItems="center" justifyContent="center" sx={{ py: 2 }}>
    <CircularProgress size={20} />
  </Stack>
);

export default function RHFAutocompleteInfiniteScroll({
  options,
  isLoadingNewPage,
  placeholder,
  loadItems,
  name = '',
  label = '',
  hasMorePages,
  value,
  onChange,
  onSelectOption,
  notFoundText = 'Não encontrado',
  setValue,
  initialSelectedValue,
  halfWidth,
  disabled,
}: Props): React.JSX.Element {
  const theme = useTheme();
  const scrollDivRef = createRef<HTMLUListElement>();
  const listRef = createRef<HTMLUListElement>();
  const wasSelected = useRef<boolean>(!!value);
  const [inputIsFocused, setInputIsFocused] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [textFieldWidth, setTextFieldWidth] = React.useState(0);
  const [selectedValue, setSelectedValue] = useState<
    AutoCompleteValueInterface | undefined
  >(initialSelectedValue);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const popoverId = open ? 'simple-popover' : undefined;

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { clientHeight, scrollHeight, scrollTop } = event.currentTarget;
    if (
      clientHeight + scrollTop >= scrollHeight &&
      !isLoadingNewPage &&
      hasMorePages
    ) {
      loadItems(value || '', true);
    }
  };

  const handleClosePopper = () => {
    if (!inputIsFocused) {
      setAnchorEl(null);
      setOpen(false);
      setInputIsFocused(false);
    }
  };

  // get textfield width
  useEffect(() => {
    if (!buttonRef.current) return;
    const resizeObserver = new ResizeObserver(e => {
      if (e[0]) {
        setTextFieldWidth(e[0].contentRect.width);
      }
    });
    resizeObserver.observe(buttonRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Controller
      name={name}
      disabled={disabled}
      render={({ fieldState: { error } }) => {
        return (
          <Box position="relative" width={halfWidth ? '45%' : '100%'}>
            <Button
              ref={buttonRef}
              fullWidth
              onClick={handleClick}
              disableRipple
              sx={{
                backgroundColor: 'transparent',
                padding: 0,
                ':hover': {
                  backgroundColor: 'transparent !important',
                },
                cursor: 'initial',
                '#simple-popover': {
                  top: '-30px',
                },
              }}
            >
              <TextField
                label={label}
                InputProps={{
                  endAdornment: inputIsFocused ? (
                    <Iconify
                      icon="material-symbols:arrow-drop-down"
                      color={theme.palette.text.secondary}
                    />
                  ) : (
                    <Iconify
                      icon="material-symbols:arrow-drop-up"
                      color={theme.palette.text.secondary}
                    />
                  ),
                }}
                id={popoverId}
                autoComplete="off"
                placeholder={placeholder}
                onBlurCapture={() => {
                  if (!wasSelected.current) {
                    setValue?.('');
                    setSelectedValue(undefined);
                  }
                }}
                value={value}
                error={!!error}
                helperText={error ? error?.message : ' '}
                focused={inputIsFocused}
                onChange={(
                  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  wasSelected.current = false;
                  setSelectedValue(undefined);
                  onChange?.(e);
                }}
                onFocus={() => {
                  setInputIsFocused(true);
                }}
                onBlur={() => {
                  setInputIsFocused(false);
                }}
                sx={{
                  flex: 1,
                  zIndex: 1,
                }}
              />
            </Button>

            <ClickAwayListener onClickAway={() => handleClosePopper()}>
              <Popper
                modifiers={[
                  {
                    name: 'flip',
                    enabled: false,
                  },
                ]}
                onScroll={handleScroll}
                id={popoverId}
                open={open}
                sx={{
                  zIndex: 2,
                  overflowX: 'hidden !important',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 1,
                  width: textFieldWidth,
                  boxShadow: '0px 3px 28px rgba(0,0,0,0.25)',
                  top: '-26px !important',
                }}
                anchorEl={anchorEl}
                placement="bottom-start"
              >
                {!isLoadingNewPage && options?.length > 0 && (
                  <Box
                    onScroll={handleScroll}
                    ref={scrollDivRef}
                    sx={{
                      maxHeight: 300,
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      p: 1,
                      scrollBehavior: 'smooth',
                    }}
                  >
                    <List ref={listRef}>
                      {options.map(
                        (option: AutoCompleteValueInterface, index) => (
                          <ListItem
                            id={`p${index}`}
                            onClick={() => {
                              wasSelected.current = true;
                              onSelectOption(option);
                              setSelectedValue(option);
                              handleClosePopper();
                            }}
                            sx={{
                              transition: '0.1s ease-in-out',
                              backgroundColor:
                                selectedValue?.id === option.id
                                  ? `${theme.palette.primary.main}50`
                                  : 'transparent',
                              cursor: 'pointer',
                              borderRadius: 1,
                              ':hover': {
                                backgroundColor: theme.palette.divider,
                              },
                            }}
                            key={option.id}
                          >
                            <Typography width="100%">{option.label}</Typography>
                          </ListItem>
                        )
                      )}
                      {hasMorePages && Loader}
                    </List>
                  </Box>
                )}
                {isLoadingNewPage && Loader}
                {!isLoadingNewPage && options?.length === 0 && (
                  <Typography
                    sx={{
                      padding: 2,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {notFoundText}
                  </Typography>
                )}
              </Popper>
            </ClickAwayListener>
          </Box>
        );
      }}
    />
  );
}
